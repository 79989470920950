export default ($) => ({
	openpopup(id){
        $('.popup'+id).fadeIn(300);
        $('body').addClass('lock');
    },
    closepopup(id){
        $('.popup'+id).fadeOut(300);
      	$('body').removeClass('lock');  
    },
    btnpopup(){
        const _th = this
        $('.close-popup,.overlay').on('click', function(e){
            e.preventDefault();
            const idpopup = '#'+$(this).parents('.popup').attr('id')
            _th.closepopup(idpopup)
        })
    },
    init(){
        this.btnpopup()
    }
})