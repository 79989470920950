import * as $ from "jquery";
import header from "./libs/header.js";
import popup from "./libs/popup.js";
import responsiveImage from "./libs/responsive-images.js";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'select2';
import 'select2/dist/css/select2.min.css';
import "slick-carousel";

const responsive = responsiveImage($);

window.$ = $;

function onlyNumberKey(evt) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
}

$(document).ready(() => {
    $('.select2').select2({
        width: '100%'
    })

    header($).init();
    popup($).btnpopup();

    const elemBanner = $(".banner-top figure img, .wrap-desc figure img");
    responsive.responsiveImage(elemBanner, {
        type: "image",
    });

    const bannerHome = $(".banner-home figure img");
    responsive.responsiveImage(bannerHome, {
        type: "image",
    });

    const bannerLiving = $(
        ".living .slider-living .slider-living__list figure img"
    );
    responsive.responsiveImage(bannerLiving, {
        type: "image",
    });

    const section360 = $(".sec-360 .wrap-360 figure img");
    responsive.responsiveImage(section360, {
        type: "image",
    });

    const sectionVideo = $(".video .wrap-video__bg img");
    responsive.responsiveImage(sectionVideo, {
        type: "image",
    });

    $(".onlynumber").keypress(function (e) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
        }
    });

    $(".onlytext").keypress(function (e) {
      let keyCode = e.keyCode || e.which;

      //Regex for Valid Characters i.e. Alphabets and Space.
      let regex = /^[A-Za-z ]+$/;

      //Validate TextBox value against the Regex.
      let isValid = regex.test(String.fromCharCode(keyCode));
      return isValid;
    });
});

// window.openPopup = (id) => {
// 	popup($).openpopup(id)
// }
