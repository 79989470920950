import { gsap, TweenLite, TweenMax, ScrollToPlugin} from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax, ScrollToPlugin); 

export default ($) => ({ 
  floatingMenu(){
    let header = $("header"),
      fnc = {}
          
    fnc.runfnc = function () {
      let topmin =  $(window).height();
      let min = 70;
      if($('.contact').length){
        if($(window).scrollTop() > min){
          if(!header.hasClass("float")){
            header.addClass("float");
            $('header .box-search').addClass('hide');
            $('header .box-search').fadeOut();
            $('header .i-search').removeClass('active');
            $('header .search-float').removeClass('active');
            $('header .book').removeClass('hide');
            $('.content-middle').addClass('on-stiky');
            
          }
        }else{
          if(header.hasClass("float")){
            header.removeClass("float");
            $('header').removeClass('active');
            $('.burger-menu').removeClass('active');
            $('header nav').removeClass('hide');
            $('header .box-search').fadeOut();
            $('header .box-search').removeClass('hide');
            $('.content-middle').removeClass('on-stiky');
          }
        }
      }else{
        if($(window).scrollTop() > topmin){
          if(!header.hasClass("float")){
            header.addClass("float");
            $('header .box-search').addClass('hide');
            $('header .box-search').fadeOut();
            $('header .i-search').removeClass('active');
            $('header .search-float').removeClass('active');
            $('header .book').removeClass('hide');
            $('.content-middle').addClass('on-stiky');
          }
        }else{
          if(header.hasClass("float")){
            header.removeClass("float");
            $('header').removeClass('active');
            $('.burger-menu').removeClass('active');
            $('header nav').removeClass('hide');
            $('header .box-search').fadeOut();
            $('header .box-search').removeClass('hide');
            $('header .book').removeClass('hide');
            $('.content-middle').removeClass('on-stiky');
          }
        }
      }

      if($('.misc').length){
        $("footer .vector").addClass("black");
      }
    } 
    $(window).scroll(function () {
        fnc.runfnc();
    })
    fnc.runfnc();
  },
  
  paggingSection(){
    let act = function (top) {
      let ctop, cbottom, section;
      $("section").each(function () {
        section = $(this);
        ctop = section.offset().top;
        cbottom = (section.offset().top + section.outerHeight());
        if (ctop <= top && cbottom >= top) {
          if (!section.hasClass('dark')) {
            $("header").removeClass('white');
          } else if (section.hasClass('dark')) {
            $("header").addClass('white');  
          }
          if (!section.hasClass('dining')) {
            $("header .book").removeClass('hide');
          } else if (section.hasClass('dining')) {
            $("header .book").addClass('hide');
          }
        }
      });
    };
    let vect = function (top) {
      let ctop, cbottom, section;
      $("section").each(function () {
        section = $(this);
        ctop = section.offset().top - ($(window).height() - 70);
        cbottom = (section.offset().top + section.outerHeight()) - ($(window).height());
        if (ctop <= top && cbottom >= top) {
          if (!section.hasClass('dark')) {
            $("footer .vector").addClass("black");
          }
          else if (section.hasClass('dark')) {
            $("footer .vector").removeClass("black");
          }
        }
      });
    };

    let lastScrollTop = 0
    $(window).scroll(function () {
      let direction = "down",
        scrolltop = $(window).scrollTop();
      if (scrolltop < lastScrollTop) {
        direction = "up";
      }
      act(scrolltop);
      vect(scrolltop);
      lastScrollTop = scrolltop;
    });
  },

  burgerMenu(){
    const burgerMenu = $('.burger-menu');
    const head = $('header');
		burgerMenu.on('click', function(){
      $('header nav').removeClass('hide');
      $('header .box-search').removeClass('hide');
			if(! $(this).hasClass('active')) {
				$(this).addClass('active');
				head.addClass('active');
			} else {
				$(this).removeClass('active');
				head.removeClass('active');
        $('header .box-search').fadeOut();
        $('header .search-float').removeClass('active');
			}
		});
    if($(window).width() < 1023){
      $('nav li.have-child-mobile > .ico').on('click',function(e){
        e.preventDefault()
        $(this).parent().find('ul').slideToggle()
        $(this).parent().toggleClass('active')
      })
    }
  },

  search(){
    const search = $('header .i-search');
    const fsearch = $('header .search-float');
    const box = $('header .box-search');
    const input = box.find('input');
    const nav = $('header nav');
    const book = $('header .book');
		search.on('click', function(){
			if(! $(this).hasClass('active')) {
				$(this).addClass('active');
				box.fadeIn();
        input.focus();
				nav.addClass('hide');
				book.addClass('hide');
			} else {
				$(this).removeClass('active');
				box.fadeOut();
        input.blur(function(){
          $(this).val('');  
        });
				nav.removeClass('hide');
				book.removeClass('hide');
			}
		});
		fsearch.on('click', function(){
			if(! $(this).hasClass('active')) {
				$(this).addClass('active');
				box.fadeIn();
        input.focus();
				nav.addClass('hide');
			} else {
				$(this).removeClass('active');
				box.fadeOut();
        input.blur(function(){
          $(this).val('');  
        });
				nav.removeClass('hide');
			}
		});
  },
  scrollBanner() {
    $(".scroll-down a").on('click', function (e) {
        e.preventDefault();
        // $('html, body').animate({ scrollTop: heightwindow + 10 }, 1000);
        gsap.to($(window), 1.2, {  
            scrollTo: {                
                y: $(".banner-top, .banner").height() + 10                      
            },
            ease: "Power2.easeOut",
            overwrite: 5    
        });
    });
  },
  scrollContact() {
    $(".scroll-contact a").on('click', function (e) {
        e.preventDefault();
        // $('html, body').animate({ scrollTop: heightwindow + 10 }, 1000);
        gsap.to($(window), 1.2, {  
            scrollTo: {                
                y: $(".wrap-map")                    
            },
            ease: "Power2.easeOut",
            overwrite: 5    
        });
    });
  },

	init(){
    this.floatingMenu()
    this.paggingSection()
    this.burgerMenu()
    this.search()
    this.scrollBanner()
    this.scrollContact()
  }
})