export default ($) => ({
  supportsWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  },
  responsiveImage(elem, e) {
    const webp_support = this.supportsWebp(),
        ext = webp_support ? "-webp" : "",
        this_ = this

    let etype = e.type
        if(etype === undefined){
            etype = "background";
        }
        const window_width = $(window).width();
        elem.each(function () {
          let flag = false
          let images_url = ''
          if (window_width >= 1280 && $(this).attr('has_load') != 'large') {
            images_url = $(this).attr('data-img-large'+ext);
            if (images_url == undefined || images_url == "") {
                images_url = $(this).attr('data-img-large');
            }
            $(this).attr('has_load', 'large');
            flag = true;
          } else if (window_width < 1280 && window_width >= 768 && $(this).attr('has_load') != 'medium') {
            images_url = $(this).attr('data-img-medium'+ext);
            if (images_url == undefined || images_url == "") {
                images_url = $(this).attr('data-img-medium');
            }
            $(this).attr('has_load', 'medium');
            flag = true;
          } else if (window_width < 768 && window_width >= 0 && $(this).attr('has_load') != 'small') {
            images_url = $(this).attr('data-img-small'+ext);
            if (images_url == undefined || images_url == "") {
                images_url = $(this).attr('data-img-small');
            }
            $(this).attr('has_load', 'small');
            flag = true;
          }
          if (images_url == undefined) {
            images_url = $(this).attr('data-img-large');
            $(this).attr('has_load', 'large');
          }

          if (flag){
            if(etype == "background"){
                $(this).css('background-image', 'url('+images_url+')');
            }else{
                $(this).attr('src', images_url);
            }
          }
        });

    var resizeTimer;

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            this_.responsiveImage(elem, e)
        }, 500);
    });
  },
})